<template>
  <Vue3autocounter :duration="0.25" :start-amount="startAmount" :end-amount="endAmount" separator=" " :decimals="hasDecimals" :decimal-separator="locale.currency.decimalSeparator" :prefix="locale.currency.signBefore ? `${locale.currency.signLocal} ` : undefined" :suffix="locale.currency.signBefore ? undefined : ` ${locale.currency.signLocal}`" />
</template>
<script setup>

import Vue3autocounter from 'vue3-autocounter';

const props = defineProps({
  value: Number,
  maxOneDecimal: Boolean
});

const locale = useLocale().getLocale();

const startAmount = ref(props.value);
const endAmount = ref(props.value);

const hasDecimals = computed(() => {
  let result = 0;

  if (props.value * 10 % 1 !== 0) {
    result = props.maxOneDecimal ? 1 : 2;
  }
  else if (props.value % 1 !== 0) {
    result = 1;
  }

  return result;
});

watch(() => props.value, () => {
  startAmount.value = endAmount.value;
  endAmount.value = props.value;
});

</script>